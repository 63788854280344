

























































































































import Vue from "vue";
import Axios from 'axios';
let md5 = require('js-md5');
export default Vue.extend({
  components: {},
  data: () => ({
    account:window.localStorage["account"],
    pass:window.localStorage["pass"],
    schoolname:window.localStorage["schoolname"],
  }),
  beforeCreate() {
    /*自动登录 */
    if(window.localStorage["token"])
      //window.location.href = "main.html";
      console.log("存在登录信息.....")
  },
  mounted(){
    if (!window.localStorage["schoolname"]){
      this.schoolname=""
    }
  },
  methods: {
    getCurrentTime() {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  
    },
    gologin() {    
      console.log(this.getCurrentTime())
      let pass = md5.base64('777'+this.pass)
      Axios({
        url:"/login",
        method:"post",
        data:{
          account:this.account,
          pass:pass,
          schoolname:this.schoolname,
          currentTime :this.getCurrentTime()
        }
      }).then(
        re=>{
          /* 为了自动登录 */
          window.localStorage["schoolname"]=this.schoolname
          window.localStorage["account"]=this.account

          window.localStorage["pass"]=this.pass
          /* 这个是真的没什么办法，因为不能异步注册导航守卫,所以要用 RSA 非对称加密*/
          window.localStorage["level"]=re.data.level
          window.localStorage["adminkind"]=re.data.adminkind
          window.localStorage["editState"]=re.data.editState
          window.localStorage["adminmajor"]=re.data.adminmajor
          window.localStorage["admin_name"]=re.data.name


          if( re.data.auth!=null){
            window.localStorage["auth"]=JSON.stringify(re.data.auth)   
            
          }
          if (this.schoolname=="杭州电子科技大学")
            window.location.href = `hdu.html?id=${re.data._id}&token=${re.data.token}`
          //从这里进入浙江工业大学活动管理，帐号为：zhegongda！  
          else if (this.schoolname=="浙江工业大学"&&this.account=="zhegongda"){
             window.location.href = `zjutactivity.html`;
          }
          else if (this.schoolname=="浙江工业大学"){
             window.location.href = `zjut.html?id=${re.data._id}&token=${re.data.token}`;
          }
          else if (this.schoolname=="浙江海洋大学"){
             window.location.href = `zjou.html?id=${re.data._id}&token=${re.data.token}`;
          }else if (this.schoolname=="中国计量大学"){
            window.location.href = `cjlu.html?id=${re.data._id}&token=${re.data.token}`;
          }else if (this.schoolname=="浙江大学"){
            window.location.href = `zju.html?id=${re.data._id}&token=${re.data.token}`;
          }

        }
      ).catch(err=>{
        console.log(err)
        alert("登录失败了.........")
      })
      
    }
  }
});
