import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import {serverUrl} from '../url';

//Vue.use(Vant);
Vue.config.productionTip = false
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
const isPro = Object.is(process.env.NODE_ENV, 'production')
const URL= isPro ? serverUrl : 'api/'
axios.defaults.baseURL = URL;
Vue.prototype.$axios = axios;

new Vue({
  render: h => h(App)
}).$mount('#app')
