







import Vue from 'vue'
import login from './views/Login.vue'
export default Vue.extend({
    components: {
        login
    }
})
